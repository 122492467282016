import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './mobilenav.css';
import assets from '../../assets';

class MobileNav extends Component {

    state = {
        navOpenStatus: false
    };

    navOperator = (reload) => {
        if (reload) window.location.reload();
        this.setState(prevState => ({
            navOpenStatus: !prevState.navOpenStatus
        }));
    }

    render () {
    return (
        <>
        <div className='mobilenav'>
            <div onClick={()=>this.navOperator()}>TADIC FILIP</div>
            <img src={assets.weblogo} />
        </div>

        <div className='mobilemenu' style={{display: this.state.navOpenStatus ? 'block' : 'none'}}>
            <div className='closer' onClick={()=>this.navOperator()}>
            <span>✕</span>
            </div>

            <ul>
                <li onClick={()=>this.navOperator(true)}><Link to='/'>HOME</Link></li>
                {/* <li onClick={()=>this.navOperator(true)}><Link to='/about'>ABOUT</Link></li> */}
                <li onClick={()=>this.navOperator(true)}><Link to='/brutalism'>PROJECTS</Link>
                    <ul className='mobilesubmenu'>
                        <li onClick={()=>this.navOperator(true)}><Link to='/brutalism'>BRUTALISM</Link></li>
                        <li onClick={()=>this.navOperator(true)}><Link to='/csm1'>CSM</Link></li>
                        <li onClick={()=>this.navOperator(true)}><Link to='/sportswear1'>SPORTSWEAR</Link></li>
                        <li onClick={()=>this.navOperator(true)}><Link to='/thesis1'>THESIS</Link></li>
                    </ul>
                </li>
                <li onClick={()=>this.navOperator(true)}><Link to='/csm'>LOOKBOOK</Link>
                    <ul className='mobilesubmenu'>
                        <li onClick={()=>this.navOperator(true)}><Link to='/csm'>CSM</Link></li>
                        <li onClick={()=>this.navOperator(true)}><Link to='/knitwear'>KNITWEAR</Link></li>
                        <li onClick={()=>this.navOperator(true)}><Link to='/puffer'>PUFFER</Link></li>
                        <li onClick={()=>this.navOperator(true)}><Link to='/sportswear'>SPORTSWEAR</Link></li>
                        <li onClick={()=>this.navOperator(true)}><Link to='/thesis'>THESIS</Link></li>
                    </ul>
                </li>
                <li onClick={()=>this.navOperator(true)}><Link to='/flats'>ARTWORK</Link>
                    <ul className='mobilesubmenu'>
                        <li onClick={()=>this.navOperator(true)}><Link to='/flats'>FLATS</Link></li>
                        <li onClick={()=>this.navOperator(true)}><Link to='/illustrations'>ILLUSTRATIONS</Link></li>
                    </ul>
                </li>
            </ul>
        </div>
        </>
    );
    }
};

export default MobileNav;