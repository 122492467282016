import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import Content from './pages/Content';
import assets from './assets';

class App extends Component {

  render() {
    return (
      <Router>

        <div className='App'>

        <section className='contentbox'>

        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>

          {/* <Route exact path='/about'>
            <Content pather='about'/>
          </Route> */}

          <Route exact path='/brutalism'>
            <Content pather='projects' title='BRUTALISM' assets={assets.brutalism} />
          </Route>

          <Route exact path='/csm1'>
            <Content pather='projects' title='CSM' assets={assets.csm1} />
          </Route>

          <Route exact path='/sportswear1'>
            <Content pather='projects' title='SPORTSWEAR' assets={assets.sport1} />
          </Route>

          <Route exact path='/thesis1'>
            <Content pather='projects' title='THESIS' assets={assets.thesis1} />
          </Route>

          <Route exact path='/csm'>
            <Content pather='lookbook' title='CSM' assets={assets.csm}/>
          </Route>

          <Route exact path='/knitwear'>
            <Content pather='lookbook' title='KNITWEAR' assets={assets.knitwear}/>
          </Route>

          <Route exact path='/puffer'>
            <Content pather='lookbook' title='PUFFER' assets={assets.puffer}/>
          </Route>

          <Route exact path='/sportswear'>
            <Content pather='lookbook' title='SPORTSWEAR' assets={assets.sport}/>
          </Route>

          <Route exact path='/thesis'>
            <Content pather='lookbook' title='THESIS' assets={assets.thesis}/>
          </Route>

          <Route exact path='/flats'>
            <Content pather='artwork' title='FLATS' assets={assets.flats}/>
          </Route>

          <Route exact path='/illustrations'>
            <Content pather='artwork' title='ILLUSTRATIONS' assets={assets.illustrations} />
          </Route>

          <Route path='*'>
            <Home />
          </Route>
        </Switch>

        </section>

        <footer>
          <div className='logo'>
            <img src={assets.weblogo} />
          </div>
          <div className='contacts'>
            <a target="_blank" rel="noopener noreferrer" href='https://www.instagram.com/fil_tadic/'><img src='http://yinglinhe.com/Uploads/images/2022/05/21/1653117682_banner_img.jpg' /></a>
            <a target="_blank" rel="noopener noreferrer" href='mailto:tadicfilipbrand@gmail.com'><img src='http://yinglinhe.com/Uploads/images/2022/05/21/1653117699_banner_img.jpg' /></a>
            <a target="_blank" rel="noopener noreferrer" href='https://www.linkedin.com/in/filip-tadic-492621212/'><img src='http://yinglinhe.com/Uploads/images/2022/05/21/1653117711_banner_img.jpg' /></a>
            <a target="_blank" rel="noopener noreferrer" href='https://drive.google.com/file/d/11MwRrhcy45vGWsi-ewgPbpdFMX7yEnTw/view?usp=sharing'><img src='http://yinglinhe.com/Uploads/images/2022/05/21/1653117755_pdf.jpg' /></a>
          </div>
          <div className='footbot' style={{background: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${assets.homepage[0]})`, backgroundPosition: '25% 45%', backgroundSize: '50%'}}>
            <div>
              <p>
                SOCIAL:INSTAGRAM
              </p>
              <a href='https://www.instagram.com/fil_tadic/'>
              <p>
                @fil_tadic
              </p>
              </a>
            </div>
            <div>
              <p>
                INQUIRIES:
              </p>
              <a href='mailto:tadicfilipbrand@gmail.com'>
              <p style={{textDecoration: 'underline'}}>
                tadicfilipbrand@gmail.com
              </p>
              </a>
            </div>
          </div>
        </footer>

        </div>

      </Router>
    );
  };
};

export default App;
