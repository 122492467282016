import React, { Component } from 'react';
import ImgSwiper from '../ImgSwiper';
import './artwork.css';

class Artwork extends Component {

    render() {
    return (
        <>

        <div className='artworkWrapper'>

            <div className='artworkSliderHolder'>
                <ImgSwiper artwork={true} assets={this.props.assets} title={this.props.title} />
            </div>

        </div>
        </>
    )
    }
}

export default Artwork;