import React, { useState, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/scss';
import 'swiper/scss/navigation';
import './imgswiper.css';
import { useSwipeable } from 'react-swipeable';
import SwiperCore, {
    Navigation
  } from 'swiper';

function ImgSwiper(props) {

    SwiperCore.use([Navigation]);

    const [state, setState] = useState({swiping: 1});

    const [windowDimensions, setWindowDimensions] = useState({width: window.innerWidth, height: window.innerHeight});

    const params = {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        },
        slidesPerView: 'auto',
        centeredSlides: false,
        spaceBetween: props.artwork ? 1000 : 10
    };

    const config = {
        delta: 100,
        preventDefaultTouchmoveEvent: false,
        trackTouch: true,
        trackMouse: false,
        rotationAngle: 0
    };

    const handlers = useSwipeable({
        ...config
    });
    
    const updateDimensions = () => {
        setWindowDimensions({width: window.innerWidth, height: window.innerHeight});
    };
    
    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    return (
        <section className={props.title === 'ILLUSTRATIONS' ? 'spotlite navshrinker' : 'spotlite'} >
            <Swiper
            initialSlide={0}
            activeSlideKey={0}
            {...params}
            {...handlers}
            >
                {props.assets.map((element, i) => {
                return <div key={i} className={!props.artwork ? 'nonartslide' : ''}>
                <img 
                src={element} style={{transform: `scale(${state.swiping}) translateY(${state.swiping === 1 ? state.swiping : state.swiping*10}vh)`}}></img>
                {props.artwork ? 
                // <div className='swipeDescription'>
                // <h1>TITLE</h1>
                // <p>MEDIUM</p>
                // <p>SIZE</p>
                // </div>
                <></>
                :
                <></>
                }
                </div>
                })}

            </Swiper>
        </section>
    );
};

export default ImgSwiper;