import React, { useEffect, useState } from 'react';
import '../App.css';
import TopNav from '../components/TopNav';
import MobileNav from '../components/MobileNav';
import assets from '../assets';

function Home(props) {

    const [windowDimensions, setWindowDimensions] = useState({width: window.innerWidth, height: window.innerHeight});

    const updateDimensions = () => {
        setWindowDimensions({width: window.innerWidth, height: window.innerHeight});
    };
    
    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    return (
        <section className='homewrapper'>
            <img src={assets.homepage[1]} />
            {windowDimensions.width < 1181 ?
            <MobileNav />
            :
            <TopNav />
            }
            <div className='topimage' />
        </section>
    );
};

export default Home;