import React, { useEffect, useState } from 'react';
import '../App.css';
import Artwork from '../components/Artwork';
import Lookbook from '../components/Lookbook';
import Projects from '../components/Projects';
import TopNav from '../components/TopNav';
import MobileNav from '../components/MobileNav';

const Content = (props) => {

    const [windowDimensions, setWindowDimensions] = useState({width: window.innerWidth, height: window.innerHeight});

    const updateDimensions = () => {
        setWindowDimensions({width: window.innerWidth, height: window.innerHeight});
    };
    
    useEffect(() => {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    return (
        <div className='contentwrapper'>

            {windowDimensions.width < 1181 ?
            <MobileNav />
            :
            <TopNav section={2} />
            }

                {props.pather === 'lookbook' ? 
                <Lookbook title={props.title} assets={props.assets} />
                :
                props.pather === 'projects' ?
                <Projects title={props.title} assets={props.assets} />
                :
                props.pather === 'artwork' ?
                <Artwork title={props.title} assets={props.assets} />
                :
                <></>
                }

        </div>
    );
};

export default Content;