import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './topnav.css';
import assets from '../../assets';

class TopNav extends Component {

    state = {
        activeTab: ''
    }

    contentRefresher = () => {
        window.location.reload();
    };

    render () {
    return (
        <div className={this.props.section === 2 ? 'navbox nav2' : 'navbox'}>

            {this.props.section === 2 ?
            <div className='logonav'>
                <img src={assets.weblogo} />
            </div>
            :
            <></>
            }

            <ul>
                <li><Link to='/'>HOME</Link></li>
                {/* <li onClick={()=>this.contentRefresher()} style={window.location.pathname.slice(1) === 'about' ? {borderBottom: '1px solid black'} : {}}><Link to='/about'>ABOUT</Link></li> */}
                <li className='navdropper' onClick={()=>this.contentRefresher()} style={
                    window.location.pathname.slice(1) === 'brutalism' ||
                    window.location.pathname.slice(1) === 'csm1' ||
                    window.location.pathname.slice(1) === 'sportswear1' ||
                    window.location.pathname.slice(1) === 'thesis1' ? {borderBottom: '1px solid black'} : {}}>

                    <Link to='/brutalism'>PROJECTS</Link>

                    <ul className='navsubmenu'>
                        <li><Link to='/brutalism'>BRUTALISM</Link></li>
                        <li><Link to='/csm1'>CSM</Link></li>
                        <li><Link to='/sportswear1'>SPORTSWEAR</Link></li>
                        <li><Link to='/thesis1'>THESIS</Link></li>
                    </ul>
                </li>
                <li className='navdropper' onClick={()=>this.contentRefresher()} style={
                    window.location.pathname.slice(1) === 'csm' ||
                    window.location.pathname.slice(1) === 'knitwear' ||
                    window.location.pathname.slice(1) === 'puffer' ||
                    window.location.pathname.slice(1) === 'sportswear' ||
                    window.location.pathname.slice(1) === 'thesis' ? {borderBottom: '1px solid black'} : {}}>

                    <Link to='/csm'>LOOKBOOK</Link>

                    <ul className='navsubmenu'>
                        <li><Link to='/csm'>CSM</Link></li>
                        <li><Link to='/knitwear'>KNITWEAR</Link></li>
                        <li><Link to='/puffer'>PUFFER</Link></li>
                        <li><Link to='/sportswear'>SPORTSWEAR</Link></li>
                        <li><Link to='/thesis'>THESIS</Link></li>
                    </ul>
                </li>
                <li className='navdropper' onClick={()=>this.contentRefresher()} style={
                    window.location.pathname.slice(1) === 'flats' ||
                    window.location.pathname.slice(1) === 'illustrations' ? {borderBottom: '1px solid black'} : {}}>

                    <Link to='/flats'>ARTWORK</Link>

                    <ul className='navsubmenu'>
                        <li><Link to='/flats'>FLATS</Link></li>
                        <li><Link to='/illustrations'>ILLUSTRATIONS</Link></li>
                    </ul>
                </li>
            </ul>
        </div>
    );
    }
};

export default TopNav;