import homepagebottom from './assets/homepage/homepagebottom.jpg'
import homepagemain from './assets/homepage/homepagemain.jpg'
import flat1 from './assets/artwork/flats/flat1.jpg'
import flat2  from './assets/artwork/flats/flat2.jpg'
import flat3 from './assets/artwork/flats/flat3.jpg'
import flat4 from './assets/artwork/flats/flat4.jpg'
import flat5 from './assets/artwork/flats/flat5.jpg'
import flat6 from './assets/artwork/flats/flat6.jpg'
import flat7 from './assets/artwork/flats/flat7.jpg'
import flat8 from './assets/artwork/flats/flat8.jpg'
import flat9 from './assets/artwork/flats/flat9.jpg'
import ill1 from './assets/artwork/illustrations/illustration1.jpg'
import ill2 from './assets/artwork/illustrations/illustration2.jpg'
import ill3 from './assets/artwork/illustrations/illustration3.jpg'
import ill4 from './assets/artwork/illustrations/illustration4.jpg'
import ill5 from './assets/artwork/illustrations/illustration5.jpg'
import ill6 from './assets/artwork/illustrations/illustration6.jpg'
import ill7 from './assets/artwork/illustrations/illustration7.jpg'
import ill8 from './assets/artwork/illustrations/illustration8.jpg'
import ill9 from './assets/artwork/illustrations/illustration9.jpg'
import ill10 from './assets/artwork/illustrations/illustration10.jpg'
import ill11 from './assets/artwork/illustrations/illustration11.jpg'
import csm1 from './assets/lookbooks/csmlookbook/csm1.jpg'
import csm2 from './assets/lookbooks/csmlookbook/csm2.jpg'
import csm3 from './assets/lookbooks/csmlookbook/csm3.jpg'
import csm4 from './assets/lookbooks/csmlookbook/csm4.jpg'
import csm5 from './assets/lookbooks/csmlookbook/csm5.jpg'
import csm6 from './assets/lookbooks/csmlookbook/csm6.jpg'
import csm7 from './assets/lookbooks/csmlookbook/csm7.jpg'
import csm8 from './assets/lookbooks/csmlookbook/csm8.jpg'
import csm9 from './assets/lookbooks/csmlookbook/csm9.jpg'
import knit1 from './assets/lookbooks/knitwearlookbook/knit1.jpg'
import knit2 from './assets/lookbooks/knitwearlookbook/knit2.jpg'
import puff1 from './assets/lookbooks/pufferlookbook/puff1.jpeg'
import puff2 from './assets/lookbooks/pufferlookbook/puff2.jpeg'
import sport1 from './assets/lookbooks/sportwearlookbook/sport1.jpg'
import sport2 from './assets/lookbooks/sportwearlookbook/sport2.jpg'
import sport3 from './assets/lookbooks/sportwearlookbook/sport3.jpg'
import sport4 from './assets/lookbooks/sportwearlookbook/sport4.jpg'
import thesis1 from './assets/lookbooks/thesislookbook/thesis1.jpg'
import thesis2 from './assets/lookbooks/thesislookbook/thesis2.jpg'
import thesis3 from './assets/lookbooks/thesislookbook/thesis3.jpg'
import thesis4 from './assets/lookbooks/thesislookbook/thesis4.jpg'
import thesis5 from './assets/lookbooks/thesislookbook/thesis5.jpg'
import thesis6 from './assets/lookbooks/thesislookbook/thesis6.jpg'
import thesis7 from './assets/lookbooks/thesislookbook/thesis7.jpg'
import thesis8 from './assets/lookbooks/thesislookbook/thesis8.jpg'
import thesis9 from './assets/lookbooks/thesislookbook/thesis9.jpg'
import b1 from './assets/projects/brutalism/b1.jpg'
import b2 from './assets/projects/brutalism/b2.jpg'
import b3 from './assets/projects/brutalism/b3.jpg'
import b4 from './assets/projects/brutalism/b4.jpg'
import b5 from './assets/projects/brutalism/b5.jpg'
import b6 from './assets/projects/brutalism/b6.jpg'
import b7 from './assets/projects/brutalism/b7.jpg'
import b8 from './assets/projects/brutalism/b8.jpg'
import b9 from './assets/projects/brutalism/b9.jpg'
import b10 from './assets/projects/brutalism/b10.jpg'
import b11 from './assets/projects/brutalism/b11.jpg'
import b12 from './assets/projects/brutalism/b12.jpg'
import b13 from './assets/projects/brutalism/b13.jpg'
import b14 from './assets/projects/brutalism/b14.jpg'
import b15 from './assets/projects/brutalism/b15.jpg'
import pcsm1 from './assets/projects/csm/csm1.jpg'
import pcsm2 from './assets/projects/csm/csm2.jpg'
import pcsm3 from './assets/projects/csm/csm3.jpg'
import pcsm4 from './assets/projects/csm/csm4.jpg'
import pcsm5 from './assets/projects/csm/csm5.jpg'
import pcsm6 from './assets/projects/csm/csm6.jpg'
import pcsm7 from './assets/projects/csm/csm7.jpg'
import pcsm8 from './assets/projects/csm/csm8.jpg'
import pcsm9 from './assets/projects/csm/csm9.jpg'
import pcsm10 from './assets/projects/csm/csm10.jpg'
import pcsm11 from './assets/projects/csm/csm11.jpg'
import pcsm12 from './assets/projects/csm/csm12.jpg'
import psport1 from './assets/projects/sportswear/sport1.jpg'
import psport2 from './assets/projects/sportswear/sport2.jpg'
import psport3 from './assets/projects/sportswear/sport3.jpg'
import psport4 from './assets/projects/sportswear/sport4.jpg'
import psport5 from './assets/projects/sportswear/sport5.jpg'
import psport6 from './assets/projects/sportswear/sport6.jpg'
import psport7 from './assets/projects/sportswear/sport7.jpg'
import psport8 from './assets/projects/sportswear/sport8.jpg'
import psport9 from './assets/projects/sportswear/sport9.jpg'
import psport10 from './assets/projects/sportswear/sport10.jpg'
import psport11 from './assets/projects/sportswear/sport11.jpg'
import psport12 from './assets/projects/sportswear/sport12.jpg'
import psport14 from './assets/projects/sportswear/sport14.jpg'
import psport15 from './assets/projects/sportswear/sport15.jpg'
import psport16 from './assets/projects/sportswear/sport16.jpg'
import psport17 from './assets/projects/sportswear/sport17.jpg'
import pthesis1 from './assets/projects/thesis/thesis1.jpg'
import pthesis2 from './assets/projects/thesis/thesis2.jpg'
import pthesis3 from './assets/projects/thesis/thesis3.jpg'
import pthesis4 from './assets/projects/thesis/thesis4.jpg'
import pthesis5 from './assets/projects/thesis/thesis5.jpg'
import pthesis6 from './assets/projects/thesis/thesis6.jpg'
import pthesis7 from './assets/projects/thesis/thesis7.jpg'
import pthesis8 from './assets/projects/thesis/thesis8.jpg'
import pthesis9 from './assets/projects/thesis/thesis9.jpg'
import pthesis10 from './assets/projects/thesis/thesis10.jpg'
import pthesis11 from './assets/projects/thesis/thesis11.jpg'
import pthesis12 from './assets/projects/thesis/thesis12.jpg'
import pthesis13 from './assets/projects/thesis/thesis13.jpg'
import pthesis14 from './assets/projects/thesis/thesis14.jpg'
import weblogo from './assets/homepage/weblogo.png'

const assets = {
    homepage: [
        homepagebottom, homepagemain
    ],
    flats: [
        flat1, flat2, flat3, flat4, flat5, flat6, flat7, flat8, flat9
    ],
    illustrations: [
        ill1, ill2, ill3, ill4, ill5, ill6, ill7, ill8, ill9, ill10, ill11
    ],
    csm: [
        csm1, csm2, csm3, csm4, csm5, csm6, csm7, csm8, csm9
    ],
    knitwear: [
        knit1, knit2
    ],
    puffer: [
        puff1, puff2
    ],
    sport: [
        sport1, sport2, sport3, sport4
    ],
    thesis: [
        thesis1, thesis2, thesis3, thesis4, thesis5, thesis6, thesis7, thesis8, thesis9
    ],
    brutalism: [
        b1, b2, b3, b4, b5, b6, b6, b8, b9, b10, b11, b12, b13, b14, b15
    ],
    csm1: [
        pcsm1, pcsm2, pcsm3, pcsm4, pcsm5, pcsm6, pcsm7, pcsm8, pcsm9, pcsm10, pcsm11, pcsm12
    ],
    sport1: [
        psport1, psport2, psport3, psport4, psport5, psport6, psport7, psport8, psport9, psport10,
        psport11, psport12, psport14, psport15, psport16, psport17
    ],
    thesis1: [
        pthesis1, pthesis2, pthesis3, pthesis4, pthesis5, pthesis6, pthesis7, pthesis8, pthesis9,
        pthesis10, pthesis11, pthesis12, pthesis13, pthesis14
    ],
    weblogo: weblogo
};

export default assets;